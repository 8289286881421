
import { Vue, Component } from 'vue-property-decorator'
import { appName } from '@/env'

import mainModule from '@/store/main'
import surveyModule from '@/store/survey'
import userModule from '@/store/user'

@Component
export default class MainView extends Vue {
  isLoaded = false
  appName = appName

  links = [
    { text: 'Surveys', route: { name: 'admin-survey-all' } },
    { text: 'Users', route: { name: 'admin-users-all' } }
    // { text: 'Settings', route: { name: 'admin-settings' } }
    // { text: 'Dashboard', route: { name: 'admin-dashboard' } }
  ]

  async mounted(): Promise<void> {
    await userModule.actionLoadUsers()
    await surveyModule.actionLoad()
    this.isLoaded = true
  }

  async logout(): Promise<void> {
    await mainModule.actionLogOut()
  }
}
